import React from "react";
import "./index.css"
import Home from "./Pages/Home"

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
